








































































import {Component, Ref, Vue} from "vue-property-decorator";
import {getModule} from "vuex-module-decorators";
import DialogModule from "@/store/DialogModule";
import Dialog from "@/models/vue/Dialog";
import Promo from "@/models/Promo";
import PromoService from "@/services/PromoService";
import LangModule from "@/store/LangModule";

@Component
export default class PromosView extends Vue {
	lang: any = getModule(LangModule).lang
	@Ref() readonly form!: HTMLFormElement
	dialog: boolean = false
	promos: Promo[] = []
	promo: Promo = new Promo()
	page: number = 1
	pageCount: number = 0
	loading: boolean = false
	search: string = ""
	headers = [
		// { text: "Id", value: "id", width: "200px" },
		{text: this.lang.name, value: "name", width: "200px"},
		{text: this.lang.document, value: "document", width: "200px"},
		{text: this.lang.date, value: "createdAt", width: "200px"},
		{text: this.lang.createdBy, value: "createdBy", width: "200px"},
	]
	file: File | null = null
	rulesFile = [(v: any) => v ? true : this.lang.documentRequired]

	created() {
		PromoService.getPromos(this, this.promos)
	}

	rowClick(promo: Promo) {
		this.$router.push({path: "/promos/" + promo.id})
	}

	openUrl(url: string) {
		window.open(url, "_blank");
	}

	openCreateDialog() {
		if (this.form) this.form.resetValidation()
		this.dialog = true
		this.promo = new Promo()
		this.file = null
	}

	createPromo() {
		if (this.form.validate()) {
			getModule(DialogModule).showDialog(new Dialog(
					this.lang.warning,
					this.lang.promoCreateQuestion,
					() => PromoService.postPromo(this, this.promo, this.file)
			))
		}
	}
}
