var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',[_c('v-row',{staticClass:"py-1 grey lighten-3",attrs:{"align":"center","no-gutters":""}},[_c('h5',{staticClass:"mx-3 grey--text text--darken-2 font-weight-medium"},[_vm._v(_vm._s(_vm.lang.specialSales))]),_c('v-spacer'),_c('v-btn',{staticClass:"mx-3",attrs:{"small":"","depressedv":"","color":"primary"},on:{"click":function($event){return _vm.openCreateDialog()}}},[_vm._v(_vm._s(_vm.lang.newPromo))])],1)],1),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"hide-details":"auto","outlined":"","clearable":"","append-icon":"mdi-magnify","label":_vm.lang.search},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.promos,"hide-default-footer":"","loading":_vm.loading,"items-per-page":10,"show-select":false,"page":_vm.page,"loading-text":_vm.lang.loading,"search":_vm.search,"no-results-text":_vm.lang.noResults,"no-data-text":_vm.lang.noResults,"disable-sort":""},on:{"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event},"click:row":_vm.rowClick},scopedSlots:_vm._u([{key:"header.name",fn:function(ref){
var header = ref.header;
return [_c('h3',{staticClass:"grey lighten-2 py-2 font-weight-medium full-height d-flex justify-center align-center"},[_vm._v(_vm._s(header.text))])]}},{key:"header.document",fn:function(ref){
var header = ref.header;
return [_c('h3',{staticClass:"grey lighten-2 py-2 font-weight-medium full-height d-flex justify-center align-center"},[_vm._v(_vm._s(header.text))])]}},{key:"header.createdAt",fn:function(ref){
var header = ref.header;
return [_c('h3',{staticClass:"grey lighten-2 py-2 font-weight-medium full-height d-flex justify-center align-center"},[_vm._v(_vm._s(header.text))])]}},{key:"header.createdBy",fn:function(ref){
var header = ref.header;
return [_c('h3',{staticClass:"grey lighten-2 py-2 font-weight-medium full-height d-flex justify-center align-center"},[_vm._v(_vm._s(header.text))])]}},{key:"item.document",fn:function(ref){
var item = ref.item;
return [(item.document)?_c('td',[_c('v-chip',{attrs:{"outlined":"","color":"primary","label":""},on:{"click":function($event){$event.stopPropagation();return _vm.openUrl(item.document.url)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-file")]),_vm._v(" "+_vm._s(item.document.baseName)),(item.document.extension)?_c('span',[_vm._v("."+_vm._s(item.document.extension))]):_vm._e()],1)],1):_vm._e()]}}])})],1)],1)],1),_c('v-pagination',{attrs:{"length":_vm.pageCount,"total-visible":8},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"400"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline primary--text"},[_vm._v(_vm._s(_vm.lang.newPromo))]),_c('v-card-text',[_c('v-form',{ref:"form"},[_c('v-text-field',{attrs:{"label":_vm.lang.name},model:{value:(_vm.promo.name),callback:function ($$v) {_vm.$set(_vm.promo, "name", $$v)},expression:"promo.name"}}),_c('v-file-input',{attrs:{"show-size":"","counter":"","label":_vm.lang.document,"rules":_vm.rulesFile},model:{value:(_vm.file),callback:function ($$v) {_vm.file=$$v},expression:"file"}})],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.createPromo()}}},[_vm._v(_vm._s(_vm.lang.create))]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(_vm._s(_vm.lang.quit))])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }